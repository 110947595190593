import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilitaService } from '../services/utilita.service';

export class DocumentiIMMAGINE {

  public idTicket?: number;
  public NomeFileUno?: string;
  public NomeFileDue?: string;
  public idStatoTicket?: number;
  public DataScontrino?: Date;
  public Nome?: string;
  public Cognome?: string;
  public Note?: string;
  public Email?: string;
  public Cellulare?: string;
  public Telefono?: string;
  public sFileBase64Uno: string;
  public sFileBase64Due: string;

  constructor() {
    this.NomeFileUno = "";
    this.NomeFileDue = "";

    this.Nome = "";
    this.Cognome = "";
    this.Note = "";
   // this.Email = "";
 //   this.Cellulare = "";
 //  this.Telefono = "";
    this.idStatoTicket = 1;
       this.DataScontrino = new Date();
     /* this.Visibile = 0;*/
    this.idTicket = 0;
    this.sFileBase64Uno = "";
    this.sFileBase64Due = "";
  }

}

@Component({
  selector: 'app-ticket-assistenza',
  templateUrl: './ticket-assistenza.component.html',
  styleUrls: ['./ticket-assistenza.component.css']
})
export class TicketAssistenzaComponent implements OnInit {
  frmInserimento!: FormGroup;
  @ViewChild("fileInputUno") fileInputUno: any; fileInputresultUno: any;
  @ViewChild("fileInputDue") fileInputDue: any; fileInputresultDue: any;
  public objimmagine: DocumentiIMMAGINE = new DocumentiIMMAGINE();
  titolo: string = '';

  constructor(
    private srvUtilita: UtilitaService,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private fb: FormBuilder,

    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    
    const dataOggi = new Date()

    const dataAl = new Date().setDate(dataOggi.getDate())
    const dataDAl = new Date(dataAl)
    let txtData = dataDAl.toISOString().substring(0, 10)


    this.frmInserimento = this.fb.group({
      idTicket: [0],
      Nome: [null, [Validators.required]],
      Cognome: [null, [Validators.required]],
      Email: [null],
      Telefono: [null],
      Cellulare: [null],
      Note: [null, [Validators.required]],
      DataScontrino: [txtData]
    })


  
  }

  
  changeListenerUno(event: any): void {
    this.readThis1(event.target );
  }

  changeListenerDue(event: any): void {
    this.readThis2(event.target );
  }
  readThis1(inputValueUno: any): void {
    var fileUno: File = inputValueUno.files[0];
    
    var myReaderUno: FileReader = new FileReader();
    
    myReaderUno.onloadend = (e) => {
      this.fileInputresultUno = myReaderUno.result;
    }
    
    myReaderUno.readAsDataURL(fileUno);
   
  }



  readThis2(inputValueDue: any): void {
  
    var fileDue: File = inputValueDue.files[0];
    var myReaderUno: FileReader = new FileReader();
    var myReaderDue: FileReader = new FileReader();
     
    myReaderDue.onloadend = (e) => {
      this.fileInputresultDue = myReaderDue.result;
    }
   
    myReaderDue.readAsDataURL(fileDue);
  }


  resetInputFile() {
    this.fileInputUno.nativeElement.value = "";
    this.fileInputDue.nativeElement.value = "";
  }


   
  btnSalva() {
    console.log('ticket')
    if (this.fileInputUno.nativeElement.files && this.fileInputUno.nativeElement.files[0] && this.fileInputDue.nativeElement.files && this.fileInputDue.nativeElement.files[0]) {
      this.objimmagine.NomeFileUno = this.fileInputUno.nativeElement.files[0].name;
      this.objimmagine.NomeFileDue = this.fileInputDue.nativeElement.files[0].name;
      this.objimmagine.sFileBase64Uno = this.fileInputresultUno.split(',')[1];
      this.objimmagine.sFileBase64Due = this.fileInputresultDue.split(',')[1];

      this.objimmagine.DataScontrino = this.frmInserimento.value.DataScontrino;

      console.log('this.objimmagine salva', this.objimmagine)
      
      this.srvUtilita.postObj('Assistenza/CreaTicket', this.objimmagine).subscribe((res: any) => {
        // this.srvUtilita.getObj('Assistenza/GetTicketDettaglioDettaglio?idTicket=' + res.idTicket).subscribe((res: any) => {
        console.log(res);
          this.resetInputFile();
        this.showMessage('Operazione avvenuta correttamente.', 'OK');
 
          
        this.router.navigate(['/inserimento-avvenuto-correttamente/' + res], { queryParams: { id: res } })
   // }) 
    }, (err: any) => {
        console.log(err);
          //this.resetInputFile();
          this.showMessage('Errore: I dati non sono stati inseriti in modo corretto.', 'OK')
      })
    } else {
     this.showMessage('Attenzione, nessun file caricato. ', 'OK');
    }
  }

  showMessage(message: string, action: string) {
    this.snackBar.open(message, action, { duration: 3000 })
  }

  btnAnnulla() { }
}
