<mat-card>
  <mat-card-content>


    <div class="container">
      <div fxLayout="row wrap" fxLayout.xs="column">
        <div fxFlex="50%" fxFlex.xs="100%">
          <img src="assets/images/outlet-divani.png" />
        </div>
        <div fxFlex="50%" fxFlex.xs="100%">
          <img src="assets/images/pg-arredamenti.png" />
        </div>
      </div>
    </div>

      <br />
      <mat-card-title> TICKET ASSISTENZA </mat-card-title>
      <br />

      <div class="container">


        <mat-toolbar>
          UTENTE
        </mat-toolbar>

        <form [formGroup]='frmInserimento' class='inserimento-form'>
          <div style="padding:30px;">
            <div fxLayout="row wrap">
              <div fxFlex="50%" fxFlex.xs="100%">
                <mat-form-field class="full-width">
                  <input [(ngModel)]="objimmagine.Cognome" [ngModelOptions]="{standalone: true}"
                         id="Cognome"
                         matInput 
                         placeholder="Cognome" />
                </mat-form-field>
              </div>

              <div fxFlex="50%" fxFlex.xs="100%">
                <mat-form-field class="full-width">
                  <input [(ngModel)]="objimmagine.Nome" [ngModelOptions]="{standalone: true}"
                         id="Nome"
                         matInput 
                         placeholder="Nome" />
                </mat-form-field>
              </div>
            </div>

            <div fxLayout="row wrap">
              <div fxFlex="50%" fxFlex.xs="100%">
                <mat-form-field class="full-width">
                  <input [(ngModel)]="objimmagine.Email" [ngModelOptions]="{standalone: true}"
                         id="Email"
                         matInput type="email"
                         placeholder="Email" />
                </mat-form-field>
              </div>

              <div fxFlex="25%" fxFlex.xs="100%">
                <mat-form-field class="full-width">
                  <input [(ngModel)]="objimmagine.Telefono" [ngModelOptions]="{standalone: true}"
                         id="Telefono"
                         matInput
                         placeholder="Telefono" />
                </mat-form-field>
              </div>

              <div fxFlex="25%" fxFlex.xs="100%">
                <mat-form-field class="full-width">
                  <input [(ngModel)]="objimmagine.Cellulare" [ngModelOptions]="{standalone: true}"
                         id="Cellulare"
                         matInput
                         placeholder="Cellulare" />
                </mat-form-field>
              </div>
            </div>
          </div>
        </form>

        <mat-toolbar>
          FOTO SEGNALAZIONE
        </mat-toolbar>

        <div style="padding:30px;">

          <div fxLayout="row wrap">
            <form>
              <input #fileInputUno type="file" accept="image/jpeg, image/jpg" (change)="changeListenerUno($event)" />
            </form>
          </div>
        </div>

        <mat-toolbar>
          FOTO SCONTRINO
        </mat-toolbar>

        <div style="padding:30px;">

          <div fxLayout="row wrap">
            <form>
              <input #fileInputDue type="file" accept="image/jpeg, image/jpg" (change)="changeListenerDue($event)" />
            </form>
          </div>
        </div>

        <mat-toolbar>
          DATA SCONTRINO
        </mat-toolbar>

        <form [formGroup]='frmInserimento' class='inserimento-form'>
          <div style="padding:30px;">
            <div fxLayout="row wrap">
              <div fxFlex="20%" fxFlex.xs="100%">
                <mat-form-field class="full-width">
                <input formControlName="DataScontrino"
                         id="DataScontrino" type="date"
                         matInput 
                         placeholder="Data Scontrino" />
                </mat-form-field>
              </div>
            </div>
          </div>
        </form>

        <mat-toolbar>
          PROBLEMA
        </mat-toolbar>

        <div style="padding:30px;">
          <form [formGroup]='frmInserimento' class='inserimento-form'>
            <div fxLayout="row wrap">
              <div fxFlex="100%" fxFlex.xs="100%">
                <mat-form-field class="full-width">
                  <textarea [(ngModel)]="objimmagine.Note" [ngModelOptions]="{standalone: true}"
                            id="Note" rows="10"
                            matInput  cols="10"
                            placeholder="Note"></textarea>
                </mat-form-field>
              </div>
            </div>
          </form>
        </div>

        <div style="padding:30px;">
          <div mat-dialog-actions>
            <button mat-raised-button color="primary" 
                    (click)="btnSalva()"
                    cdkFocusInitial>
              Salva
            </button>
          </div>
        </div>

      </div>


  </mat-card-content>
</mat-card>
