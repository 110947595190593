import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { MenuItems,Menu } from '../../../shared/menu-items/menu-items';

import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { UtilitaService } from '../../../services/utilita.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: []
})
export class AppSidebarComponent implements OnDestroy {
  mobileQuery: MediaQueryList;
  sUtenteLogin: any;
  sNominativoLogin: any;
  listMenu: Menu[] = [];
  listVociProfilo: Menu[] = [];

  private _mobileQueryListener: () => void;
    iCodiceLogin: string = '';
  iCodiceUtente: string = '';
  sUtenteImpersonificazione = '';


public config: PerfectScrollbarConfigInterface = {};
 
  status = true;

  itemSelect: number[] = [];
  parentIndex = 0;
  childIndex = 0;

  setClickedRow(i: number, j: number) {
    this.parentIndex = i;
    this.childIndex = j;
  }
  subclickEvent() {
    this.status = true;
  }
  scrollToTop() {
    document.querySelector('.page-wrapper')?.scroll({
      top: 0,
      left: 0
    });
  }

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher, private srvUtilita: UtilitaService,
    public menuItems: MenuItems, public router: Router,
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit(): void {
    this.sUtenteLogin = this.srvUtilita.getNome() + ' ' + this.srvUtilita.getCognome();
  // console.log(this.sUtenteLogin, 'email ')
    this.setForm(); 
   
  }

  setForm() {

    this.iCodiceUtente = this.srvUtilita.getIDUtente();
    this.iCodiceUtente = this.srvUtilita.getIDUtente();
    this.srvUtilita.getLista('Menu/GetMenuByCodiceClienteSoloChild?iCodice=' + this.iCodiceUtente + '&App=2').subscribe((res: Menu[]) => {
    //this.srvUtilita.getLista('Menu/GetMenuByCodiceCliente?iCodice=' + this.iCodiceUtente + '&App=2').subscribe((res: Menu[]) => {
      console.log(res);
      this.listMenu = res;
    }, (err: any) => {
      console.log(err);
      this.listMenu = [];
    })
 
    //cognome e nome nel menu a sinistra
   /* let sNominativoLogin = this.srvUtilita.getNominativoLogin();
    this.sUtenteLogin = sNominativoLogin + " " + this.iCodiceLogin;

    let sNominativoUtente = this.srvUtilita.getNominativoUtente();
    this.sUtenteImpersonificazione = sNominativoUtente + " " + this.iCodiceUtente;*/
  }

  resetmenu() {
    this.listMenu = this.menuItems.getMenuitem();
  }
  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  UtenteLogout() {
    
  }
}
