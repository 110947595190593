<mat-card>
  <mat-card-content>
    <mat-card-title>
      Immagine: {{stringa}}

      <button mat-raised-button
              color="primary" style="float:right"
              (click)="btnRitonaAlTicket()">
        Ritorna al TICKET
      </button>
    </mat-card-title>

    <br /><br />
      <div class="container">
        <div fxLayout="row wrap" *ngIf="isEditFoto">
          <div fxFlex="100%" fxFlex.xs="100%">
            <img src="https://cloud.codher.it/Documenti/{{objimmagine.NomeFileIMMAGINE}}"
                 style="width:100%; height: 100%;" />
          </div>
        </div>


        <div fxLayout="row wrap" *ngIf="isEditScontrino">
          <div fxFlex="100%" fxFlex.xs="100%">
            <img src="https://cloud.codher.it/Documenti/{{objimmagine.NomeFileImmagineSCONTRINO}}"
                 style="width:100%; height: 100%;" />
          </div>
        </div>
      </div>

  </mat-card-content>
</mat-card>
