import { MediaMatcher } from '@angular/cdk/layout';
import {ChangeDetectorRef, Component,OnDestroy,AfterViewInit} from '@angular/core';
import { MenuItems } from '../../shared/menu-items/menu-items';
import { TranslateService } from '@ngx-translate/core';

/** @title Responsive sidenav */
@Component({
  selector: 'app-full-layout',
  templateUrl: 'full.component.html',
  styleUrls: []
})
export class FullComponent implements OnDestroy, AfterViewInit {
  mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;

  sLingua: string = '';

  constructor(
    private translate: TranslateService,
    changeDetectorRef: ChangeDetectorRef, 
    media: MediaMatcher,
    public menuItems: MenuItems,
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

   
    translate.setDefaultLang('it');


  }



  ngOnit() {
   // this.sLingua = JSON.parse(localStorage.getItem("username") || "0").CodiceLingua;
   this.translate.setDefaultLang('it');
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
  ngAfterViewInit() { }

  public selectedLanguage: any = {
    language: 'Italiano',
    code: 'it',
    type: 'IT',
    icon: 'it'
  }


  public languages: any[] = [
    {
      language: 'Italiano',
      code: 'it',
      type: 'IT',
      icon: 'it'
    },
    {
      language: 'English',
      code: 'en',
      type: 'en',
      icon: 'us'
    },
    
    {
      language: 'Deutsch',
      code: 'de',
      icon: 'de'
    }
  ]


  changeLanguage(lang: any) {
    this.translate.use(lang.code)
    this.selectedLanguage = lang;
    console.log('lang', lang)
  }


}
