import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

export class DocumentiIMMAGINE {

  // public idImmagine?: number;
  public NomeFileIMMAGINE?: string;
  public NomeFileImmagineSCONTRINO?: string;
  public CodArticolo?: string;
  // public DataInserimento?: Date;
  // public Visibile?: number;

  public sFileBase64: string;

  constructor() {
    this.NomeFileIMMAGINE = "";
    this.NomeFileImmagineSCONTRINO = "";
    this.CodArticolo = "";
    /*  this.DataInserimento = new Date();
      this.Visibile = 0;*/
    this.sFileBase64 = "";
  }

}

@Component({
  selector: 'app-immagine',
  templateUrl: './immagine.component.html',
  styleUrls: ['./immagine.component.css']
})
export class ImmagineComponent implements OnInit {
  @ViewChild("fileInput") fileInput: any; fileInputresult: any;
  public objimmagine: DocumentiIMMAGINE = new DocumentiIMMAGINE();
  isEditFoto: number = 0
  isEditScontrino: number = 0
  idimmagine: any;
  stringa: string = '';

  constructor(private route: ActivatedRoute,
    private router: Router,) {   
    this.route.queryParams.subscribe((params: any) => {
      const id = params['id'];
      console.log('id', id)
      this.idimmagine = id;
      
    })

  }

  ngOnInit(): void {
    if (this.idimmagine == 'foto') {
      console.log('immagine foto')
      this.stringa = "FOTO"
      this.isEditFoto = 1;
    }

    if (this.idimmagine == 'scontrino') {
      console.log('immagine scontrino')
      this.stringa = "SCONTRINO"
      this.isEditScontrino = 1;
    }
  }

  btnRitonaAlTicket() {
    this.router.navigate(['/utente/ticket-assistenza/'])
  }

}
