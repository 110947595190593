<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div class="main-container">
  <!-- ============================================================== -->
  <!-- Topbar - style you can find in header.scss -->
  <!-- ============================================================== -->
  <mat-toolbar color="primary" class="topbar telative">
    <!-- ============================================================== -->
    <!-- Logo - style you can find in header.scss -->
    <!-- ============================================================== -->
    <div class="navbar-header">
      <a class="navbar-brand" href="index.html">
        <!-- Logo icon --><b>
          <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
          <!-- Dark Logo icon -->
          <!--<img src="assets/images/logo-light-icon.png" alt="homepage" class="dark-logo">-->
          <!-- Light Logo icon -->
          <img src="assets/images/loghi uniti.jpg" alt="homepage" class="light-logo" style="width: 60%; ">
        </b>
        <!--End Logo icon -->
        <!-- Logo text -->
        <span fxShow="false" fxShow.gt-xs>
          <!-- dark Logo text -->
          <!--<img src="assets/images/logo-light-icon.png" alt="homepage" class="dark-logo">-->
          <!-- Light Logo text -->
        <!--  <img src="assets/images/logo-light-text.png" class="light-logo" alt="homepage"> -->

        </span>
      </a>
    </div>
    <!-- ============================================================== -->
    <!-- sidebar toggle -->
    <!-- ============================================================== -->


    <span fxFlex></span>
    <!-- ============================================================== -->
    <!-- app header component - style you can find in header.scss / header.component.ts-->
    <!-- ============================================================== -->

  </mat-toolbar>
  <!-- ============================================================== -->
  <!-- End Topbar - style you can find in pages.scss -->
  <!-- ============================================================== -->
  <mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 0 : 0">
    <!-- ============================================================== -->
    <!-- Sidebar - style you can find in sidebar.scss -->
    <!-- ============================================================== -->
    <!--<mat-sidenav #snav id="snav" class="dark-sidebar pl-xs" [mode]="mobileQuery.matches ? 'side' : 'over'" fixedTopGap="0" [opened]="mobileQuery.matches" [disableClose]="mobileQuery.matches">-->
    <!--<app-sidebar></app-sidebar>-->
    <!--</mat-sidenav>-->
    <!-- ============================================================== -->
    <!-- Sidebar - style you can find in sidebar.scss -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Page container - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <mat-sidenav-content class="page-wrapper">

      <div class="page-content">

        <router-outlet><app-spinner></app-spinner></router-outlet>

      </div>

    </mat-sidenav-content>
    <!-- ============================================================== -->
    <!-- Page container - style you can find in pages.scss -->
    <!-- ============================================================== -->
  </mat-sidenav-container>
</div>
