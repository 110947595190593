import { Injectable } from '@angular/core'

import { UtilitaService } from 'src/app/services/utilita.service';
import { Router } from '@angular/router'

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'

import { AuthGuard } from '../auth.guard'
 import { Md5 } from 'md5-typescript'
import { MatSnackBar } from '@angular/material/snack-bar';
 


interface User {
  username: string
  password: string
  idUtenteRuolo: number
  idUtente: number
  nome: boolean
  cognome: string
  email: string
  isRuoloFiltroRegistroPresenze: number
  token: string
  IdCliente: number
}

@Injectable({
  providedIn: 'root',
})

export class AuthService {
  userData: any
  static loggedIn = false;
  constructor(
    private http: HttpClient,
    public srvUtilita: UtilitaService,
    public router: Router,
    private snackBar: MatSnackBar
  ) {
    localStorage.clear();
  }

  login(username: string, password: string) {
  
    password = Md5.init(password);
    const userLog = {
      pUserName: username,
      pPassword: password,
      software:1
    }
    return this.http.post<any>(this.srvUtilita.getAPI_BASE_PATH() + 'Login/CheckLogin', userLog)
  }

  SignIn(username: string, password: string) {

    this.login(username, password).subscribe((res: any) => {

      res = res;
      console.log("res", res);
      const userAngular = {
        username: res.Email,
        Email: res.Email,
        Nome: res.Nome,
        Cognome: res.Cognome,
        password: "",
        idUtente: res.Id,
        token: res.token,
        idNegozio: res.idNegozio,
        Negozio: res.Negozio
      }
      /*valido solo in debug poi eliminare altrimenti non ha senso il cript*/
      localStorage.setItem('username', JSON.stringify(userAngular))
      localStorage.setItem('usernameUtente', JSON.stringify(userAngular))
      /*******/
      console.log('loggato');
      /*valido solo in debug poi eliminare altrimenti non ha senso il cript*/
      localStorage.setItem('username', JSON.stringify(userAngular))
 
      /*******/
    


    
      let sVariabile = res.Id.toString();
      sVariabile = this.srvUtilita.Cripta(sVariabile);
      localStorage.setItem('idUtente', sVariabile);

      sVariabile = res.Nome.toString();
      sVariabile = this.srvUtilita.Cripta(sVariabile);
      localStorage.setItem('Nome', sVariabile);

      sVariabile = res.Cognome.toString();
      sVariabile = this.srvUtilita.Cripta(sVariabile);
      localStorage.setItem('Cognome', sVariabile);

      sVariabile = res.Email.toString();
      sVariabile = this.srvUtilita.Cripta(sVariabile);
      localStorage.setItem('username', sVariabile);

      sVariabile = res.idNegozio.toString();
      sVariabile = this.srvUtilita.Cripta(sVariabile);
      localStorage.setItem('idnegozio', sVariabile);

      sVariabile = res.Negozio.toString();
      sVariabile = this.srvUtilita.Cripta(sVariabile);
      localStorage.setItem('Negozio', sVariabile);

      //sVariabile = res.token.toString();
      //sVariabile = this.srvUtilita.Cripta(sVariabile);
      //localStorage.setItem('token', sVariabile);

    //  AuthService.loggedIn = true;
  
   //   this.router.navigate(['/dashboard']);

    }
   ,
      (err: any) => {

        this.showMessage(err.error.Message, 'OK');
      })
  }
  public iIsLoggedIn() {
    return AuthService.loggedIn;
  }



  showMessage(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }
  async SignOut() {
 //   localStorage.removeItem('idPersonaValidato')
    localStorage.removeItem('username')
    localStorage.removeItem('user')
   // AuthService.loggedIn = false;
   // this.router.navigate(['/utente/ticket-assistenza/'])
  }

  showMessageAlert(message: string, action: string) {
    this.snackBar.open(message, action, { duration: 100000 })
  }
  public getUser(): User {
    return JSON.parse(localStorage.getItem('user')||'');
  }

 

  public getOnlyHeaders(request: any = undefined, contentType = 'application/json') {
    let headers = new HttpHeaders();
    if (contentType !== "") {
      headers = headers.set('Content-Type', contentType);
    }

    if (this.iIsLoggedIn() && this.getUser()) {

      let token = this.getUser().token;
      headers = headers.set("Authorization", "Bearer " + token);
    }

    headers.set("Access-Control-Allow-Origin", "*");
    headers.set("Access-Control-Allow-Credentials", "true");
    headers.set("Access-Control-Allow-Methods", "GET,HEAD,OPTIONS,POST,PUT");
    headers.set("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept");
    return { headers };
  }
  // tslint:disable-next-line: no-unnecessary-initializer
  public getHeaders(request: any = undefined, contentType = 'application/json') {
    let headers = new HttpHeaders();
    if (contentType !== "") {
      headers = headers.set('Content-Type', contentType);
    }

    let params = new HttpParams();

    if (this.iIsLoggedIn() ) {

      let token = this.getUser().token;
      headers = headers.set("Authorization", "Bearer " + token);
    }

    if (request !== undefined) {
      for (let key of Object.keys(request)) {
        params = params.append(key, request[key]);
      }
    }

    headers.set("Access-Control-Allow-Origin", "*");
    headers.set("Access-Control-Allow-Credentials", "true");
    headers.set("Access-Control-Allow-Methods", "GET,HEAD,OPTIONS,POST,PUT");
    headers.set("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept");
    return { headers, params };
  }
  getDefaultGet<T extends any>(
    url:string, request = {}, header = this.getHeaders(request)
  ) {
    return this.http.get<T>(url, header).toPromise().then((data: any) => { return data; }).catch();
  }



}
