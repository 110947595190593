import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
 
import { AuthService } from './services/auth.service';

interface User {
  username: string
  password: string
  idUtenteRuolo: number
  idUtente: number
  nome: boolean
  cognome: string
  email: string
  isRuoloFiltroRegistroPresenze: number
  token: string
  idTicket: number
}


@Injectable()
export class headerInterceptor implements HttpInterceptor {

  constructor(public autorizzazione: AuthService) { }
  public getUser(): User {
    return JSON.parse(localStorage.getItem('usernameUtente')||'');
  }


  public iIsLoggedIn() {
    return AuthService.loggedIn;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    console.log("intercp");
    if (this.iIsLoggedIn() ) {

      let token = this.getUser().token;
      headers = headers.set("Authorization", "Bearer " + token);
    }



    headers.set("Access-Control-Allow-Origin", "*");
    headers.set("Access-Control-Allow-Credentials", "true");
    headers.set("Access-Control-Allow-Methods", "GET,HEAD,OPTIONS,POST,PUT");
    headers.set("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept");


    const cloneReq = req.clone({ headers });

    return next.handle(cloneReq);
  }
}

