import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UtilitaService } from '../../../services/utilita.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class AppHeaderComponent {

  constructor(
    private translate: TranslateService, public router: Router,
  ) {


    translate.setDefaultLang('it');


  }


  UtenteLogout() {
    localStorage.setItem("username", '[]');
    localStorage.setItem("usernameUtente", '[]');
    localStorage.removeItem("username");
    localStorage.removeItem("usernameUtente");
  }

  public selectedLanguage: any = {
    language: 'Italiano',
    code: 'it',
    type: 'IT',
    icon: 'it'
  }


  public languages: any[] = [
    {
      language: 'Italiano',
      code: 'it',
      type: 'IT',
      icon: 'it'
    },
    {
      language: 'English',
      code: 'en',
      type: 'en',
      icon: 'us'
    },

    {
      language: 'Deutsch',
      code: 'de',
      icon: 'de'
    }
  ]


  changeLanguage(lang: any) {
    this.translate.use(lang.code)
    this.selectedLanguage = lang;
    console.log('lang', lang)
  }
}
