import { Routes } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
 


import { AuthGuard } from './auth.guard';

 

import { NomenuComponent } from './layouts/nomenu/nomenu.component';
 
import { TicketAssistenzaComponent } from './ticket-assistenza/ticket-assistenza.component';
import { ImmagineComponent } from './immagine/immagine.component';
import { InserimentoAvvenutoCorrettamenteComponent } from './inserimento-avvenuto-correttamente/inserimento-avvenuto-correttamente.component';

export const AppRoutes: Routes = [
  {
    path: '',
    component: FullComponent,
  //  canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: '/utente/ticket-assistenza',
        pathMatch: 'full'
      },
   
      {
        path: 'inserimento-avvenuto-correttamente/:id',
        component: InserimentoAvvenutoCorrettamenteComponent,
      },
      
   
    ]
  },
 
 {
  path: 'utente',
  component: NomenuComponent,
  children: [
    
      {
      path: 'ticket-assistenza',
        component: TicketAssistenzaComponent,
      },
      
      {
        path: 'inserimento-avvenuto-correttamente/:id',
        component: InserimentoAvvenutoCorrettamenteComponent,
      },
   ]
},

];
