<mat-card class="p-0 card">
  <mat-card-content class="card-body">
    <label>Inserimento avvenuto correttamente</label>
    <br />
    <br />

    <div fxLayout="row wrap" fxLayout.xs="column">
      <div fxFlex="30%;" fxFlex.xs="100%">
        <label><b>Ticket:</b> </label>
      </div>
      <div fxFlex="70%;" fxFlex.xs="100%">
        {{objdettaglio?.idTicket}}
      </div>
    </div>

    <br />

    <div fxLayout="row wrap" fxLayout.xs="column">
      <div fxFlex="30%;" fxFlex.xs="100%">
        <label><b>Data Ticket:</b> </label>
      </div>
      <div fxFlex="70%;" fxFlex.xs="100%">
        {{objdettaglio?.dataTicket | date: 'dd/MM/yyyy' }}
      </div>
    </div>

    <br />    <br />
    <div mat-dialog-actions>
      <button mat-raised-button color="primary"
              (click)="btnRitornaAlTicket()">
        Inserisci una nuova richiesta di assistenza
      </button>
    </div>


  </mat-card-content>
</mat-card>
