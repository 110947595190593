<!-- ============================================================== -->
<!-- sidebar -->
<!-- ============================================================== -->
<!--<div class="user-profile" style="background: url(assets/images/outlet-divani.png) no-repeat; 
    background-size: 95% 70%; background-position: 0px 50px; ">

  <div class="profile-text"><a [matMenuTriggerFor]="" class=""> {{sUtenteLogin}} <i class="ti-angle-down font-12 m-l-5"></i></a></div>
  <div class="profile-img"> <img src="assets/images/users/profile.png" alt="user"> </div>
</div> -->
<mat-nav-list appAccordion>
  <mat-list-item appAccordionLink *ngFor="let menuitem of listMenu" routerLinkActive="selected" group="{{menuitem.state}}">
    <a class="" appAccordionToggle [routerLink]="['/', menuitem.state]" *ngIf="menuitem.type === 'link'">
      <mat-icon>{{ menuitem.icon }}</mat-icon>
      <span>{{ menuitem.name | translate}}</span>

      <span fxFlex></span>
       </a>
  </mat-list-item>
 </mat-nav-list> 
