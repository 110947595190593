
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})

export class UtilitaService {
  constructor(
    private http: HttpClient
  ) { }

  public static srvProduzione = true
   //quando si pubblica per DIVANI SERVER mettere srvProduzione=true
  //quando si pubblica per DIVANI in server cloud scrivere mettere srvProduzione=false
  public static API_HOST = UtilitaService.srvProduzione ? 'https://gestionale.pgdivanioutlet.it/' + 'DivaniAPI/API/' : 'https://cloud.codher.it/' + 'DivaniAPI/API/' //'http://localhost:61234/API/' //
  public static API_REPORT = UtilitaService.srvProduzione ? 'https://gestionale.pgdivanioutlet.it/' : 'https://cloud.codher.it/';

  public static API_BASE_PATH = UtilitaService.API_HOST

  getAPI_BASE_PATH() {
    return UtilitaService.API_BASE_PATH;
  }

  getHostReport() {
    return UtilitaService.API_REPORT;
  }

  getLista(sApi: string) {
    return this.http.get<any[]>(UtilitaService.API_BASE_PATH + sApi)
  }
  getObj(sApi: string) {
    return this.http.get<any>(UtilitaService.API_BASE_PATH + sApi)
  }

  public getIPAddress() {
    return this.http.get("http://api.ipify.org/?format=json");
  }

  postLista(sApi: string, obj: any) {
    return this.http.post<any[]>(UtilitaService.API_BASE_PATH + sApi, obj)
  }
  postObj(sApi: string, obj: any) {
    return this.http.post<any>(UtilitaService.API_BASE_PATH + sApi, obj)
  }
   
  /* login se passi da localstorage*/
  getUtenteLoggato() {
    if (localStorage.getItem("user")) {
      let objUtente = JSON.parse(localStorage['user']);
    }

    return JSON.parse(localStorage['user'] || '[]');
  }

  isUtenteLoggato() {
    if (localStorage.getItem("usernameUtente") && localStorage.getItem("username")) {
      let objUtenteusernameUtente = JSON.parse(localStorage['usernameUtente']);
      let objUtenteusername = JSON.parse(localStorage['username']);
      return true;
    }
    else
      return false;
  }
   

  UtenteLogout() {
    localStorage.setItem("user", '[]');
    localStorage.removeItem("user");
  }
 
  Cripta(conversion: string) {

    return CryptoJS.AES.encrypt(conversion, "Codher").toString();

  }
  Decripta(conversion: string) {
    let decrypted = CryptoJS.AES.decrypt(conversion, "Codher");
    return decrypted.toString(CryptoJS.enc.Utf8);
    //return CryptoJS.AES.decrypt(conversion, "Codher").toString(CryptoJS.enc.Utf8);
  }


  public getidUtenteLogin() {

    let sVariabile = localStorage.getItem('idUtenteLogin') || "-1";
    console.log("sVariabile", sVariabile);

    return this.Decripta(sVariabile);

  }



  public getIDUtente() {
    let sVariabile = localStorage.getItem('idUtente') || "-1";
    console.log("getIDUtente:", this.Decripta(sVariabile));
    return this.Decripta(sVariabile);

  }

  public getCodiceLogin() {

    let sVariabile = localStorage.getItem('idUtente') || "-1";
    console.log("sVariabile", sVariabile);

    return this.Decripta(sVariabile);

  }
  public getCognome() {

    let sVariabile = localStorage.getItem('Cognome') || "-1";
    console.log("sVariabile", sVariabile);
    return this.Decripta(sVariabile);

  }
  public getNome() {

    let sVariabile = localStorage.getItem('Nome') || "-1";
    console.log("sVariabile", sVariabile);
    return this.Decripta(sVariabile);

  }
  public getCodiceUtente() {

    let sVariabile = localStorage.getItem('CodiceUtente') || "-1";
    console.log("sVariabile", sVariabile);
    return this.Decripta(sVariabile);

  }
  public getListino() {
    let sVariabile = localStorage.getItem('Listino') || "";
    return this.Decripta(sVariabile);
  }
  public getListinoUtente() {
    let sVariabile = localStorage.getItem('ListinoUtente') || "";
    return this.Decripta(sVariabile);
  }
  public getListinoAutoconsumo() {
    let sVariabile = localStorage.getItem('ListinoAutoconsumo') || "";
    return this.Decripta(sVariabile);
  }
  //public getTipo() {
  //  let sVariabile = localStorage.getItem('Tipo') || "";
  //  console.log("sVariabile -tipo", sVariabile);
  //  console.log(CryptoJS.AES.decrypt("U2FsdGVkX187jvUbYx7zecFj/SCyB0K9ex1LpszuD5+3h1H0LlRgs03Jl2dI1grzhhyPHJ/ebkd5Tu2Aj4npEw==", "Codher").toString(CryptoJS.enc.Utf8));

  //  return this.Decripta(sVariabile.toString());
  //}
  public getTipoLogin() {
    let sVariabile = localStorage.getItem('Tipo') || "";
    return this.Decripta(sVariabile);
  }
  public getTipoUtente() {
    let sVariabile = localStorage.getItem('TipoUtente') || "";
    return this.Decripta(sVariabile);
  }
  public getStato() {
    let sVariabile = localStorage.getItem('Stato') || "";
    return this.Decripta(sVariabile);
  }
  public getCodiceLingua() {
    let sVariabile = localStorage.getItem('CodiceLingua') || "";
    return this.Decripta(sVariabile);
  }
  public getNominativoLogin() {
    let sVariabile = localStorage.getItem('NominativoLogin') || "";
    return this.Decripta(sVariabile);
  }
  public getNominativoUtente() {
    let sVariabile = localStorage.getItem('NominativoUtente') || "";
    return this.Decripta(sVariabile);
  }

  public getidNegozio() {
    let sVariabile = localStorage.getItem('idnegozio') || "";
    return this.Decripta(sVariabile);
  }
  public getNegozio() {
    let sVariabile = localStorage.getItem('Negozio') || "";
    return this.Decripta(sVariabile);
  }

  public getidTicket() {
    let sVariabile = localStorage.getItem('idTicket') || "";
    return this.Decripta(sVariabile);
  }


}
