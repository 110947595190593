
import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy, registerLocaleData } from '@angular/common';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FullComponent } from './layouts/full/full.component';
import { AppHeaderComponent } from './layouts/full/header/header.component';
import { AppSidebarComponent } from './layouts/full/sidebar/sidebar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DemoMaterialModule } from './demo-material-module';
import { CsvModule } from '@ctrl/ngx-csv';

import { SharedModule } from './shared/shared.module';
import { SpinnerComponent } from './shared/spinner.component';
 
import { NomenuComponent } from './layouts/nomenu/nomenu.component';
import { AuthGuard } from './auth.guard';


import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import localeItIt from '@angular/common/locales/it';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from '../environments/environment';

 
import { CanvasWhiteboardModule } from 'ng2-canvas-whiteboard';
 
import { NgApexchartsModule } from 'ng-apexcharts';
import { SafePipeModule } from 'safe-pipe';
import { MatDividerModule } from '@angular/material/divider';

/*da aggiungere*/
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { headerInterceptor } from './httpinterceptor.module';
import { TicketAssistenzaComponent } from './ticket-assistenza/ticket-assistenza.component';
import { ImmagineComponent } from './immagine/immagine.component';
import { InserimentoAvvenutoCorrettamenteComponent } from './inserimento-avvenuto-correttamente/inserimento-avvenuto-correttamente.component';

registerLocaleData(localeItIt, 'it')

export function HttpLoaderFactory(http: HttpClient) {
  // return new TranslateHttpLoader(http, './assets/i18n/', '.json');
  return new TranslateHttpLoader(http, environment.API_URL_TRADUZIONI, "");
}



@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    AppHeaderComponent,
    SpinnerComponent,
    AppSidebarComponent,
    
    NomenuComponent,
    
  
        TicketAssistenzaComponent,
        ImmagineComponent,
        InserimentoAvvenutoCorrettamenteComponent,

     
  

  ],
  imports: [
    BrowserModule,
    NgxDatatableModule, 
    BrowserAnimationsModule,
    DemoMaterialModule, 
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    HttpClientModule,
    SharedModule, 
    RouterModule.forRoot(AppRoutes, { useHash: true }),
    CsvModule,
    CanvasWhiteboardModule,
   /* TranslateModule.forRoot({
     loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),*/
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }, 
    }),
    //SurveyAngularModule,
 // SurveyModule,
   // SurveyCreatorModule,
    NgApexchartsModule,
    SafePipeModule,
    MatDividerModule,

  ],
 
  entryComponents: [ //modale
  
 


  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'it-IT' },
  /* data inizio */
    { provide: MAT_DATE_LOCALE, useValue: 'it-IT' },
     { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  /* data fine */
   // { provide: MAT_DATE_LOCALE, useValue: 'it-IT' },
  //  { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
  //  { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    AuthGuard,
     
    {
      provide: HTTP_INTERCEPTORS,
      useClass: headerInterceptor,
      multi: true
    },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
