import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilitaService } from '../services/utilita.service';
@Component({
  selector: 'app-inserimento-avvenuto-correttamente',
  templateUrl: './inserimento-avvenuto-correttamente.component.html',
  styleUrls: ['./inserimento-avvenuto-correttamente.component.css']
})
export class InserimentoAvvenutoCorrettamenteComponent implements OnInit {
  frmInserimento!: FormGroup;
  idTicket: any;
  objdettaglio: any;



  constructor(
    private router: Router, private srvUtilita: UtilitaService,
    private route: ActivatedRoute, private fb: FormBuilder,
    ) {
  
    this.idTicket = '';
    this.route.queryParams.subscribe((params: any) => {
      const id = params['id'];
      this.idTicket = id;
    })
  }

  ngOnInit(): void {

    this.frmInserimento = this.fb.group({
      idTicket: [null],
      Nome: [null],
      Cognome: [null],
      Email: [null],
      Telefono: [null],
      Cellulare: [null],
      NrScontrino: [null],
      Note: [null],
      dataTicket: [null],
      DataScontrino: [null]
    })

    this.getDettaglio()

 
  }


  btnRitornaAlTicket() {
    this.router.navigate(['/utente/ticket-assistenza/']);
  }

  getDettaglio() {
    this.srvUtilita.getObj('Assistenza/GetTicketDettaglio?idTicket=' + this.idTicket).subscribe((res: any) => {
      this.objdettaglio = res;
      console.log(res)

      const objData = new Date(res.DataScontrino);
      if (objData.getHours() == 0)
        objData.setHours(12);
      this.frmInserimento.patchValue({ DataScontrino: objData.toISOString().substring(0, 10) })

    })
  }

}
